import axios from 'axios';


// Helper function to send logs to the backend asynchronously using Axios
const sendLog = async (level, message) => {

    try {
        await axios.post('/api/logs', {
            level,
            projectName: 'CHAT ',
            message,
            callerFile: getCallerFile(),
        }, {
            headers: { 'Content-Type': 'application/json' }
        });
    } catch (error) {
        console.error('Log send failed:' + error);
    }
};

// Logger object to handle different log levels
const logger = {
    info: (message) => sendLog('info', message),
    warn: (message) => sendLog('warn', message),
    error: (message) => sendLog('error', message),
    debug: (message) => sendLog('debug', message),
};

// Function to get the caller file
const getCallerFile = () => {
    try {
        // Create a new Error object and extract the stack trace
        const stack = new Error().stack;

        if (!stack) return 'unknown.js';

        // Split the stack trace by line
        const stackLines = stack.split('\n');

        // Skip the first few lines to find the external caller file (adjust index as necessary)
        for (let i = 2; i < stackLines.length; i++) {
            if (!stackLines[i].includes('frontendLogger.js') && !stackLines[i].includes('Error')) {
                const match = stackLines[i].match(/\(([^)]+)\)/); // Extract content inside parentheses
                if (match && match[1]) {
                    // Return only the filename from the URL/path
                    return match[1].split('/').pop();
                }
            }
        }
    } catch (error) {
        return 'unknown.js';
    }
};

export default logger;
