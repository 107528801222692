import  "bootstrap/dist/css/bootstrap.min.css";
import './App.css';
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import {
    BasicStorage,
    ChatMessage,
    ChatProvider,
    Conversation,
    ConversationId,
    ConversationRole,
    IStorage,
    MessageContentType,
    Participant,
    Presence,
    TypingUsersList,
    UpdateState,
    User,
    UserStatus,
} from "@chatscope/use-chat";
import { ExampleChatService } from "@chatscope/use-chat/dist/examples";
import { Chat } from "./components/Chat";
import { Auth } from "./components/Auth";
import { nanoid } from "nanoid";
import { Col, Container, Row } from "react-bootstrap";
import { akaneModel, eliotModel, emilyModel, joeModel, users } from "./data/data";
import { AutoDraft } from "@chatscope/use-chat/dist/enums/AutoDraft";
import Cookies from "universal-cookie";
import './custom-styles.scss'; // Custom styles
import React, { useState, useCallback } from "react";
import axios from "axios";

// Set default Axios configuration
axios.defaults.withCredentials = true;
axios.defaults.baseURL = 'http://localhost:5000'; // Your backend URL

// Message and Group ID generators
const messageIdGenerator = () => nanoid();
const groupIdGenerator = () => nanoid();

const App: React.FC = () => {
    const [testUserModel, setTestUserModel] = useState<User | null>(null);
    const akaneStorage = new BasicStorage({ groupIdGenerator, messageIdGenerator });

    // Memoize serviceFactory to prevent unnecessary re-creation
    const serviceFactory = useCallback(
        (storage: IStorage, updateState: UpdateState) => {
            return new ExampleChatService(storage, updateState);
        },
        [] // Dependencies array empty to memoize the function once
    );

    // Function to handle login success and initialize user session
    const handleLoginSuccess = (sessionUser: any) => {

        // Use the sessionUser object returned from the login to create the user model
        const strId = sessionUser.keyId.toString();
        const userModel = new User({
            id: strId ,
            presence: new Presence({ status: UserStatus.Available, description: "" }),
            firstName: sessionUser.firstName || "Default Firstname",
            lastName: sessionUser.lastName || "Default Lastname",
            username: sessionUser.username || "Default Username",
            email: sessionUser.email || "default@test.com",
            avatar: sessionUser.avatar || 'https://chatscope.io/storybook/react/assets/zoe-E7ZdmXF0.svg',
            bio: sessionUser.bio || "No bio"
        });

        // Set the user model after successful login
        setTestUserModel(userModel);
    };

    // If testUserModel is not set, show the login screen
    if (!testUserModel) {
        return <Auth onLoginSuccess={handleLoginSuccess} />;
    }

    return (
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            transform: "translateX(0%)",
            opacity: "1",
          }}
        >

            <ChatProvider serviceFactory={serviceFactory} storage={akaneStorage} config={{
                typingThrottleTime: 250,
                typingDebounceTime: 900,
                debounceTyping: true,
                autoDraft: AutoDraft.Save | AutoDraft.Restore
            }}>
                <Chat user={testUserModel} />
            </ChatProvider>


        </div>
    );

}

export default App;