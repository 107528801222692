import { Children, useMemo, useEffect, useState, useRef, StrictMode } from "react";
import config from "../config/config.js";
import logger from '../config/frontendLogger.js';

import {
    MainContainer,
    Sidebar,
    ConversationList,
    Conversation,
    Avatar,
    ChatContainer,
    ConversationHeader,
    MessageGroup,
    Message,
    MessageList,
    MessageInput,
    Button,
    Search,
    ExpansionPanel,
    InfoButton,
    MessageSeparator,
} from "@chatscope/chat-ui-kit-react";
import { faSignOutAlt, faCopy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    parseISO,
    format,
    isSameDay,
    isToday,
    isYesterday,
    isThisWeek,
    isThisYear,
} from "date-fns";

import { useChat } from "@chatscope/use-chat";
import axios from "axios";
import { User } from "@chatscope/use-chat";
import Spinner from "./spinner";

import deleteIcon from "../assets/deleteIcon.png";




// Helper sleep function
const sleep = (milliseconds: number) => new Promise((resolve) => setTimeout(resolve, milliseconds));

// TypeScript Interfaces
interface TmpMsg {
    keyId: number;
    sender: string;
    receiver: string;
    recvTm: string;
    sms: string;
    portNum: number;
    hasGet: number;
    displayNumber: string;
    isRead: number;
    isDeleted?: number;
    record_id: string;
    company: string;
    fullname: string;
    estimated_revenue: string;
    fname: string;
    lname: string;
    email: string;
    idxGoipId: number;
}

interface TmpMsgCollection {
    [sender: string]: TmpMsg[];
}

interface MesgCountByUser {
    [key: string]: number;
}

export const Chat = ({ user }: { user: User }) => {

    // State Variables
    const [initialRows, setInitialRows] = useState<TmpMsg[]>([]);
    const [selectedCustomerData, setSelectedCustomerData] = useState<TmpMsg | null>(null);
    const [showSpinner, setShowSpinner] = useState<boolean>(false);
    const [arrBySender, setArrBySender] = useState<TmpMsgCollection>({});
    const [nowMessages, setNowMessages] = useState<TmpMsg[]>([]);
    const [lastSender, setLastSender] = useState<string | null>(null);
    const [stateRightSide, setStateRightSide] = useState<string>("hidden");
    // const [customerData, setCustomerData] = useState<any>(null);
    const { activeConversation, currentMessage, setCurrentMessage, sendTyping } = useChat();
    const [showCopiedMessage, setShowCopiedMessage] = useState<boolean>(false);
    const [value, setValue] = useState<string>("Search...");
    const messageListRef = useRef<HTMLDivElement>(null);

    const hasInitialized = useRef(false);  // Ref to track if initializeMessages has been called


    // Scroll to bottom when messages update
    useEffect(() => {
        if (messageListRef.current) {
            const element = messageListRef.current;
            element.scrollTop = element.scrollHeight;
        }
    }, [nowMessages]);

    // Helper function to separate messages by day
    const shouldShowDateSeparator = (messages: TmpMsg[], index: number): boolean => {
        if (index === messages.length - 1) return true; // Always show for the first message
        const prevMessageDate = parseISO(messages[index + 1].recvTm);
        const currentMessageDate = parseISO(messages[index].recvTm);
        const isNewDay = !isSameDay(prevMessageDate, currentMessageDate);
        return isNewDay;
    };

    
    const formatMessageTime = (strTime: string, relative: boolean = true): string => {

         /**
         * Formats a timestamp string into a human-readable format.
         *
         * - If `relative` is `true`:
         *   - **Today:** Returns time in "HH:mm" format.
         *   - **Yesterday:** Returns the string "Yesterday".
         *   - **Older:** Returns date in "MM/dd/yyyy" format.
         *
         * - If `relative` is `false`:
         *   - Always returns time in "HH:mm" format.
         *
         * @param strTime - The ISO timestamp string to format.
         * @param relative - Determines the formatting style. Defaults to `true`.
         * @returns A formatted date or time string based on the `relative` flag.
         */

        // Parse the ISO timestamp string to a Date object
        
        let timestamp = null;

        try {
            timestamp = parseISO(strTime);
        } catch (error) {
            console.error('Error parsing date:', error);
            return ''; // Return empty string or a default value on parse failure
        }

        if (relative) {
            if (isToday(timestamp)) {
                // Today: Return time in "HH:mm" format
                return format(timestamp, 'hh:mm a');
            } else if (isYesterday(timestamp)) {
                // Yesterday: Return "Yesterday"
                return "Yesterday";
            } else {
                // Older than yesterday: Return date in "MM/dd/yyyy" format
                return format(timestamp, 'MM/dd/yyyy');
            }
        } else {
            // Time-only formatting: Always return time in "HH:mm" format
            return format(timestamp, 'HH:mm');
        }
    };

    // Rearrange messages by sender
    const rearrangeBySender = (arr: TmpMsg[]): TmpMsgCollection => {
        const result: TmpMsgCollection = {};
        arr.forEach((item: TmpMsg) => {
            const displayNumber = formatPhoneNumber(item.sender);
            const newItem = { ...item, displayNumber }; // Add displayNumber to the message object
            if (!result[item.sender]) {
                result[item.sender] = [];
            }
            result[item.sender].push(newItem);
        });
        return result;
    };

    // Fetch messages from the backend
    const getMessages = async (): Promise<TmpMsg[] | null> => {
        try {
            logger.info(`Chat.tsx: calling  /api/chat/getMessages`);
            const response = await axios.post("/api/chat/getMessages", { id: user.id });
            const res = response.data.messages;
            return res;
        } catch (error) {
            logger.error("Error fetching messages: " + error);
            return null;
        }
    };

    // Show spinner for a limited time
    const showSpinnerLimitSeconds = (seconds: number) => {
        setShowSpinner(true);
        setTimeout(() => {
            setShowSpinner(false);
        }, seconds * 1000);
    };

    // Initialize messages on component mount
    useEffect(() => {
        initializeMessages();
    }, []);

    const initializeMessages = async () => {

        // Prevent duplicate initialization
        if (hasInitialized.current) return;
        hasInitialized.current = true;

        showSpinnerLimitSeconds(7);

        try {
            logger.info("Starting initializeMessages");

            // Fetch messages from the backend
            let data = await getMessages();

            // Validate data
            if (!data || !Array.isArray(data)) {
                logger.info("Warning: Received invalid data. Setting to an empty array.");
                data = [];
            }

            // Hide spinner
            setShowSpinner(false);

            // Store message count in localStorage
            localStorage.setItem("totalLength", data.length.toString());

            // Set initial messages
            setInitialRows(data);

            // Rearrange messages by sender if data is not empty
            if (data.length > 0) {
                const tmp = rearrangeBySender(data);
                setMsgCountByUserToLocalStorage(tmp);
                setArrBySender(tmp);
            } else {
                logger.info("No messages to display.");
            }

            // Start polling for new messages
            UpdateMsgs();
        }
        catch (error) {
            logger.error("Error initializing messages:" + error);
            setShowSpinner(false); // Ensure the spinner is hidden on error
        }
    };

  
    // Helper function to wait for two animation frames to ensure rendering has completed
    async function waitForRender() {
        return new Promise(resolve => {
            requestAnimationFrame(() => {
                requestAnimationFrame(resolve);
            });
        });
    }

    // Polling function to update messages
    const UpdateMsgs = async () => {
        let UpdateOK = true;

        try {
            while (UpdateOK) {
                // Fetch messages from the backend
                let data = await getMessages();

                if (!data || !Array.isArray(data)) {
                    logger.info("No valid data returned from the backend.");
                    return;
                }

                // Check if new messages have arrived
                let totalLength = parseInt(localStorage.getItem("totalLength") || "0");
                logger.info( `Fetched=${data.length}, stored=${totalLength} messages` );

                if (data.length > totalLength) {
                    // Update messages
                    setInitialRows(data);
                    const tmp = rearrangeBySender(data);
                    setArrBySender(tmp);

                    await waitForRender(); // Wait for the browser to render the updates

                    // Update localStorage with the new message count
                    localStorage.setItem("totalLength", data.length.toString());
                }

                // Wait for 30 seconds before polling again
                await sleep(30000);
                logger.info("Polling for new messages...");
            }
        } catch (error) {
            logger.error("Error in UpdateMsgs: " + error);
            UpdateOK = false;
            setShowSpinner(false); // Ensure spinner is hidden on error
        }
    };

    // Store message count by user in localStorage
    const setMsgCountByUserToLocalStorage = (obj: TmpMsgCollection) => {
        let jsondata: MesgCountByUser = {};
        for (const key in obj) {
            jsondata[key] = obj[key].length;
        }
        let res = JSON.stringify(jsondata);
        localStorage.setItem("mesgCountByUser", res);
    };

    const orderByRecentSender = (obj: { [key: string]: TmpMsg[] }) => {
      let tmpArrShow: { key: string; recvTm: string }[] = [];
      Object.keys(obj).forEach((key) => {
          // Access the recvTm property of the first message in the array for each sender
          tmpArrShow.push({ key, recvTm: obj[key][0].recvTm });
      });
      tmpArrShow.sort(
          (a, b) => new Date(b.recvTm).getTime() - new Date(a.recvTm).getTime()
      );
      let res: string[] = [];
      tmpArrShow.forEach((item) => {
          res.push(item.key);
      });
      return res;
  };
  


    // Handle sending of typing indicators
    const handleChange = (value: string) => {
        setCurrentMessage(value);
        if (activeConversation) {
            sendTyping({
                conversationId: activeConversation.id,
                isTyping: true,
                userId: user.id,
                content: value, // Note: Avoid sending user-typed content if privacy is a concern
                throttle: true,
            });
        }
    };

    // Handle selecting a conversation
    const onSelectLeft = (value: TmpMsg[]) => {
        setNowMessages(value);
        const selectedNumber = getSelectedUserNumber(value);
        setSelectedCustomerData(value[0]); // Assuming the first message contains customer data
        markConversationRead(selectedNumber, 1);
    };

    // Extract the phone number from selected messages
    const getSelectedUserNumber = (value: TmpMsg[]): string => {
        let lastRow: TmpMsg = value[0];
        let displayNumber: string = lastRow.hasGet !== -1 ? lastRow.sender : lastRow.receiver;
        logger.info(`${displayNumber} SELECTED!`);
        return displayNumber;
    };

    
    const sendMsg = async (lastRow: TmpMsg): Promise<boolean> => {
        // Send the message to the phone
        if (lastRow) {
            const data = {
                sms: lastRow.sms,
                to: lastRow.receiver,
                machineID: lastRow.idxGoipId, // Ensure this is sent
                from: lastRow.portNum,
                tid: "1",
            };
            try {
                const response = await axios.post("/sendMsg", data);
                logger.info("API TEST:" + response.data);
            } catch (error) {
                logger.error("Error in sendMsg:" + error);
            }
        }

        // save in chat history
        try {
            const response = await axios.post("/chat/sendMsg", { id: user.id, lastRow });
            logger.info("Saved message response:" + response.data.result);
            return response.data.result[0].keyId > 0;
        } catch (error) {
            logger.error("Error sending message:" + error);
            return false;
        }
    };

    const updateArrBySender = async (text: string) => {
        let lastRow: TmpMsg = nowMessages[nowMessages.length - 1];
        let newRow: TmpMsg | null = null;
        const now = new Date();
        const year = now.getUTCFullYear();
        const month = String(now.getUTCMonth() + 1).padStart(2, "0");
        const day = String(now.getUTCDate()).padStart(2, "0");
        const hours = String(now.getUTCHours()).padStart(2, "0");
        const minutes = String(now.getUTCMinutes()).padStart(2, "0");
        const seconds = String(now.getUTCSeconds()).padStart(2, "0");
        const currentTimeString = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
    
        if (lastRow?.hasGet != -1) {
          newRow = {
            keyId: 1,
            sender: lastRow.receiver,
            receiver: lastRow.sender,
            recvTm: currentTimeString,
            portNum: lastRow.portNum,
            sms: text,
            hasGet: -1,
            displayNumber: formatPhoneNumber(lastRow.receiver),
            isRead: 0,
            isDeleted: lastRow.isDeleted,
            record_id: lastRow.record_id,
            company: lastRow.company,
            fullname: lastRow.fullname,
            estimated_revenue: lastRow.estimated_revenue,
            fname: lastRow.fname,
            lname: lastRow.lname,
            email: lastRow.email,
            idxGoipId: lastRow.idxGoipId,
          };
        } else if (lastRow?.hasGet === -1) {
          newRow = {
            keyId: 1,
            sender: lastRow.sender,
            receiver: lastRow.receiver,
            recvTm: currentTimeString,
            portNum: lastRow.portNum,
            sms: text,
            hasGet: -1,
            displayNumber: formatPhoneNumber(lastRow.sender),
            isRead: 0,
            isDeleted: lastRow.isDeleted,
            record_id: lastRow.record_id,
            company: lastRow.company,
            fullname: lastRow.fullname,
            estimated_revenue: lastRow.estimated_revenue,
            fname: lastRow.fname,
            lname: lastRow.lname,
            email: lastRow.email,
            idxGoipId: lastRow.idxGoipId,
          };
        }
    
        if (newRow) {
          const updatedNowMessages = [newRow, ...nowMessages];
          let updatedInitRows = [newRow, ...initialRows];
          setInitialRows(updatedInitRows);
    
          setArrBySender((prevArrBySender) => {
            let updatedArrBySender = { ...prevArrBySender };
            if (newRow!.hasGet === 0) {
              updatedArrBySender[newRow!.sender] = updatedNowMessages;
            } else if (newRow!.hasGet === -1) {
              updatedArrBySender[newRow!.receiver] = updatedNowMessages;
            }
            return updatedArrBySender;
          });
          setNowMessages((prevMessages) => {
            return [...updatedNowMessages];
          });
    
          let isSaved = await sendMsg(newRow);
          console.log("Message sent:", isSaved);
        }
        setShowSpinner(false);
      };

    // Handle sending messages
    const handleSend = (text: string) => {
        updateArrBySender(text);
        setCurrentMessage("");
    };

    // Logout handler (refactored)
    const handleLogout = async () => {
        try {
            const response = await axios.post('/auth/logout');
            if (response.data.message === 'Logout successful') {
                // Redirect to login or update parent state
                window.location.reload(); // Simple approach to refresh the app
            }
        } catch (error) {
            logger.error("Error during logout:" + error);
            // Handle error appropriately, e.g., show an error message to the user
        }
    };

    // Function to handle delete button click with confirmation
    const handleDeleteConversation = async (phoneNumber: string) => {
        // Consider replacing confirm with a custom modal for better UX
        if (window.confirm("Are you sure you want to delete this conversation?")) {
            try {
                await markConversationDeleted(phoneNumber, 1);
                logger.info(`Conversation with ${phoneNumber} deleted.`);
            } catch (error) {
                logger.error("Error deleting conversation:" + error);
                // Optionally, inform the user about the error
            }
        }
    };


    // Function to format a user's phone number
    const formatPhoneNumber = (phoneNumber: string) => {
        const cleaned = ("" + phoneNumber).replace(/\D/g, "");
        const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return `+${match[1]} (${match[2]}) ${match[3]}-${match[4]}`;
        }
        return phoneNumber;
    };

    // Function to highlight and copy phone numbers
    const highlightText = (text: string, element: HTMLElement) => {
        const dataTransfer = new DataTransfer();
        dataTransfer.setData("text", text);
        const textData = dataTransfer.getData("text");
        navigator.clipboard.writeText(textData);

        const range = document.createRange();
        range.selectNodeContents(element);
        const selection = window.getSelection();
        if (selection != null) {
            selection.removeAllRanges();
            selection.addRange(range);
            // Remove the highlight immediately
            setTimeout(() => {
                selection.removeAllRanges();
            }, 0);
            setShowCopiedMessage(true);

            // Hide the copied message after 2 seconds
            setTimeout(() => {
                setShowCopiedMessage(false);
            }, 2000);
        }
    };

    // Function to filter JSON by sender
    const filterJsonBySender = (msgCollection: TmpMsgCollection, sender: string): TmpMsgCollection => {
        const filteredCollection: TmpMsgCollection = {};
        for (const key in msgCollection) {
            if (key !== sender) {
                filteredCollection[key] = msgCollection[key];
            }
        }
        return filteredCollection;
    };

    // Function to set read status in JSON by sender
    const setReadJsonBySender = (msgCollection: TmpMsgCollection, sender: string): TmpMsgCollection => {
        if (msgCollection[sender]) {
            msgCollection[sender] = msgCollection[sender].map(msg => ({ ...msg, isRead: 1 }));
        }
        return msgCollection;
    };

    // Function to mark conversation as deleted
    const markConversationDeleted = async (phoneNumber: string, isDeleted: number) => {
        let tmpArrBySender: TmpMsgCollection = { ...arrBySender };
        delete tmpArrBySender[phoneNumber];
        setArrBySender(tmpArrBySender);

        try {
            await axios.post("/conversation/markConversationDeleted", { phoneNumber, isDeleted });
        } catch (error) {
            logger.error("Error marking conversation as deleted:" + error);
            // Optionally, revert the state change or notify the user
        }
    };

    // Function to mark conversation as read
    const markConversationRead = async (phoneNumber: string, isRead: number) => {
        let tmpArrBySender: TmpMsgCollection = { ...arrBySender };
        if (tmpArrBySender[phoneNumber]) {
            tmpArrBySender[phoneNumber] = tmpArrBySender[phoneNumber].map(msg => ({
                ...msg,
                isRead: 1,
            }));
            setArrBySender(tmpArrBySender);
        }

        try {
            await axios.post("/conversation/markConversationRead", { phoneNumber, isRead });
        } catch (error) {
            logger.error("Error marking conversation as read:" + error);
            // Optionally, revert the state change or notify the user
        }
    };

      //function to display the time correctly checks if its in the week or month and renders it appropiately
  const changeTimeByMyBrowser = (strTime: any) => {
    const browserTimezone = new Date().getTimezoneOffset(); // Get the browser's timezone offset
    const timestamp = new Date(strTime);
    timestamp.setTime(timestamp.getTime()); // Adjust the time for the browser's timezone

    const now = new Date();
    const oneWeekAgo = new Date();
    oneWeekAgo.setDate(now.getDate() - 7);

    let formattedTimestamp;

    if (
      timestamp.getFullYear() === now.getFullYear() &&
      timestamp.getMonth() === now.getMonth() &&
      timestamp.getDate() === now.getDate()
    ) {
      // Today
      formattedTimestamp = new Intl.DateTimeFormat("en", {
        hour: "2-digit",
        minute: "2-digit",
      }).format(timestamp);
    } else if (
      timestamp.getFullYear() === now.getFullYear() &&
      timestamp.getMonth() === now.getMonth() &&
      timestamp.getDate() === now.getDate() - 1
    ) {
      // Yesterday
      formattedTimestamp = "Yesterday";
    } else {
      // Older than yesterday
      formattedTimestamp = `${String(timestamp.getMonth() + 1).padStart(
        2,
        "0"
      )}/${String(timestamp.getDate()).padStart(
        2,
        "0"
      )}/${timestamp.getFullYear()}`;
    }

    return formattedTimestamp;
  };
      

   //conditional logic of when to render the date seperator
  const renderDateSeparator = (messages: any, index: any) => {
    if (shouldShowDateSeparator(messages, index)) {
      return (
        // <MessageSeparator
        //   content={changeTimeByMyBrowser(messages[index].recvTm)}
        // />

        <MessageSeparator
          children={changeTimeByMyBrowser(messages[index].recvTm)}
        />
      );
    }
    return null;
  };

    return (
        <MainContainer responsive>
            <Sidebar position="left" scrollable>
                {" "}
                {/*sidebar*/}
                <ConversationHeader>
                    <Avatar src={user.avatar} name={user.username} />
                    <ConversationHeader.Content>
                        {user.username}
                    </ConversationHeader.Content>
                    <ConversationHeader.Actions>
                        <Button
                            labelPosition="right"
                            icon={<FontAwesomeIcon icon={faSignOutAlt} />}
                            onClick={handleLogout}
                        />
                    </ConversationHeader.Actions>
                </ConversationHeader>
                <Search placeholder="Search..."
                    value={value}
                    onChange={(v) => setValue(v)}
                    onClearClick={() => setValue("")} />


                <ConversationList>
                    {orderByRecentSender(arrBySender).length > 0 &&
                        orderByRecentSender(arrBySender).map((sender: string) => {
                            const senderMessages: TmpMsg[] = arrBySender[sender];

                            const latestMessage: TmpMsg =
                                senderMessages[senderMessages.length - 1]; // Use the latest message
                            const senderData: TmpMsg = senderMessages[0]; // Assuming there is at least one message for each sender

                            if (senderData.isDeleted) {
                                return null; // Skip deleted conversations
                            }
                            const [avatar, name] = [undefined, latestMessage.displayNumber];

                            const isActive = nowMessages.length > 0 && nowMessages[0].displayNumber === latestMessage.displayNumber;
                            const displayName = senderData.fullname || senderData.displayNumber; // Use number if name is null

                            const unreadClass = senderData.isRead === 0 && senderData.hasGet === 0 ? 'cs-conversation__message--unread' : '';
                            const unreadTimeClass = senderData.isRead === 0 && senderData.hasGet === 0 ? 'cs-conversation__time--unread' : '';

                            return (
                                !senderData.isDeleted && (
                                    <div
                                        key={sender}
                                        className={`cs-conversation ${isActive ? 'cs-conversation--active' : ''}`}
                                        style={{ position: 'relative' }}
                                        onClick={() => onSelectLeft(senderMessages)}
                                    >
                                        {avatar}
                                        <div className="cs-conversation__content">
                                            <div className="cs-conversation__details">
                                                <div className={`cs-conversation__name ${!senderData.fullname ? 'cs-conversation__name--number' : ''}`}>
                                                    {displayName}
                                                </div>
                                                <div className={`cs-conversation__time ${unreadTimeClass}`}>{formatMessageTime(senderData.recvTm)}</div>
                                            </div>
                                            {senderData.fullname && <div className={`cs-conversation__number ${unreadClass}`}>{senderData.displayNumber}</div>}
                                            <div className={`cs-conversation__message ${unreadClass}`}>
                                                {senderData.sms}
                                            </div>
                                        </div>
                                        {senderData.isRead === 0 && senderData.hasGet === 0 && (
                                            <div className="cs-conversation__unread-dot"></div>
                                        )}
                                        <div className="cs-conversation__actions">
                                            <img
                                                src={deleteIcon}
                                                alt="Delete"
                                                className="delete_img"
                                                onClick={(e) => {
                                                    e.stopPropagation(); // Prevent triggering the onClick of the Conversation
                                                    handleDeleteConversation(senderData.sender);
                                                }}
                                            />
                                        </div>
                                    </div>
                                )
                            );
                        })}
                </ConversationList>
            </Sidebar>

            <ChatContainer>
                {nowMessages.length > 0 && (
                    <ConversationHeader>
                        <ConversationHeader.Content
                            info={nowMessages[nowMessages.length - 1].company}
                            userName={nowMessages[nowMessages.length - 1].displayNumber}
                        />
                        <ConversationHeader.Actions>
                            <Button
                                title="Copy"
                                icon={
                                    showCopiedMessage ? (
                                        <span style={{ color: "gray", fontSize: "14px" }}> Copied!</span>
                                    ) : (
                                        <FontAwesomeIcon icon={faCopy} />
                                    )
                                }
                                style={{ cursor: "pointer", color: "black" }}
                                onClick={(e) =>
                                    highlightText(
                                        nowMessages[nowMessages.length - 1].displayNumber,
                                        e.currentTarget
                                    )
                                }
                            />
                        </ConversationHeader.Actions>
                    </ConversationHeader>
                )}
                <MessageList ref={messageListRef}>
                    {nowMessages.slice().reverse().map((msg, index) => (
                        <div key={index} className="message-block">
                            {renderDateSeparator(nowMessages, index)}                        
                            <MessageGroup
                                direction={msg.hasGet === -1 ? "outgoing" : "incoming"}
                            >
                                <MessageGroup.Messages>
                                    <Message
                                        model={{
                                            message: msg.sms,
                                            sentTime: formatMessageTime(msg.recvTm), //format(parseISO(msg.recvTm), "p"),
                                            sender: "---",
                                            position: "last",
                                            direction: msg.hasGet === -1 ? "outgoing" : "incoming",
                                        }}
                                    >
                                        <Message.Footer>
                                            {formatMessageTime(msg.recvTm)}
                                        </Message.Footer>
                                    </Message>
                                </MessageGroup.Messages>
                            </MessageGroup>
                        </div>
                    ))}
                </MessageList>

                <MessageInput
                    value={currentMessage}
                    onChange={handleChange}
                    onSend={handleSend}
                    disabled={!(nowMessages.length > 0)}
                    attachButton={false}
                    placeholder="Type here..."
                />
            </ChatContainer>
            <Sidebar position="right">
                <ExpansionPanel open title="Customer Information">
                    {selectedCustomerData ? (
                        <>
                            <p>Record ID: {selectedCustomerData.record_id}</p>
                            <p>Company: {selectedCustomerData.company}</p>
                            <p>Full Name: {selectedCustomerData.fullname}</p>
                            <p>Estimated Revenue: {selectedCustomerData.estimated_revenue}</p>
                            <p>Email: {selectedCustomerData.email }</p>
                        </>
                    ) : (
                        <p>Customer Information</p>
                    )}
                </ExpansionPanel>
            </Sidebar>
            <Spinner show={showSpinner} />
        </MainContainer>
    );
};
