import React, { useState } from "react";
import axios from "axios";
import signinImage from "../assets/signup.jpg";

// Type for login response
interface LoginResponse {
  message: string;
  sessionUser: any; // Adjust this type to match your session user structure
}

interface AuthProps {
  onLoginSuccess: (sessionUser: any) => void; // Expect sessionUser to be passed to onLoginSuccess
}

export const Auth: React.FC<AuthProps> = ({ onLoginSuccess }) => {
  const [form, setForm] = useState({ username: "", password: "" });
  const [msg, setMsg] = useState("");

  // Handle input change
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  // Handle form submission
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { username, password } = form;

    try {
      const response = await axios.post<LoginResponse>(
        "/api/auth/login", // Using baseURL set in Axios defaults
        { username, password }
      );

      const message = response.data.message;
      const sessionUser = response.data.sessionUser;

      setMsg(message);

      if (message === "Login successful") {
        onLoginSuccess(sessionUser); // Pass the sessionUser back to App.tsx
      } else {
        setMsg("Incorrect Username or Password"); // Login failed
      }
    } catch (error) {
      setMsg("Failed to login. Please try again.");
      console.error("Error during login:", error);
    }
  };

  return (
    <div className="auth__form-container">
      <div className="auth__form-container_fields">
        <div className="auth__form-container_fields-content">
          <p>Sign In</p>
          <form onSubmit={handleSubmit}>
            <div className="auth__form-container_fields-content_input">
              <label htmlFor="username">Username</label>
              <input
                name="username"
                type="text"
                placeholder="Username"
                onChange={handleChange}
                value={form.username}
                required
              />
            </div>
            <div className="auth__form-container_fields-content_input">
              <label htmlFor="password">Password</label>
              <input
                name="password"
                type="password"
                placeholder="Password"
                onChange={handleChange}
                value={form.password}
                required
              />
            </div>
            <p>{msg}</p>
            <div className="auth__form-container_fields-content_button">
              <button type="submit">Sign In</button>
            </div>
          </form>
        </div>
      </div>
      <div className="auth__form-container_image">
        <img src={signinImage} alt="sign in" />
      </div>
    </div>
  );
};
